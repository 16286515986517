<template>
  <common-modal @close="$emit('close')" height="h-max">
    <div class="flex flex-col space-y-8 max-h-96">
      <h1 class="text-xl font-semibold">
        Send Message to {{ userAccount.firstName || userAccount.first }}
      </h1>
      <common-textarea v-model="message" :placeholder="computePlaceholder" />
      <div class="w-full flex justify-end">
        <common-button
          :disabled="!message || sending"
          :loading="sending"
          width="w-40"
          @click="sendMessage"
          >Send</common-button
        >
      </div>
    </div>
  </common-modal>
</template>

<script>
  import CommonModal from "../Modal.vue";
  import APIService from "@/services/api/index";

  export default {
    name: "send-message-modal",

    props: ["user", "placeholder"],

    components: {
      CommonModal,
    },

    data() {
      return {
        message: "",
        userAccount: this.user || {},
        sending: false,
      };
    },

    computed: {
      computePlaceholder() {
        return this.placeholder
          ? this.placeholder
          : `Hi ${
              this.userAccount.firstName || this.userAccount.first
            }! Just wanted to reach out and say...`;
      },
    },

    methods: {
      sendMessage: async function () {
        if (!this.message.length)
          return this.$toasted.show(
            `You may not send an empty message.`,
            this.$toastedFailure
          );
        this.sending = true;

        let composedMessage = this.message;
        composedMessage = composedMessage.replace("<", "");
        composedMessage = composedMessage.replace(">", "");

        const data = {
          recipientIDs: [this.userAccount.id || this.userAccount._id],
          composedMessage,
        };

        const response = await APIService.chat.newBulkMessage(data);
        if (response.status === 200) {
          this.$toasted.show(
            `A message has been sent to ${
              this.userAccount.firstName || this.userAccount.first
            } ${this.userAccount.lastName || this.userAccount.last}.`,
            this.$toastedSuccess
          );
          this.$emit("close");
        } else {
          this.$toasted.show(
            `Uh oh! There was an issue.`,
            this.$toastedFailure
          );
          this.sending = false;
        }
      },
    },
  };
</script>
